import { Dialog, DialogContent } from '@mui/material';
import * as React from 'react';
import DialogHeader from './DialogHeader';
import { FileItem } from '../common/Explorer';
import FileExplorer from '../common/FileExplorer';

export interface ISelectFileDialogProps {
    open: boolean
    onClose(): void
    onConfirm?(fileItem: FileItem): void
    connectionId?: string;
}

const SelectFileDialog: React.FunctionComponent<
    React.PropsWithChildren<ISelectFileDialogProps>
> = (props: React.PropsWithChildren<ISelectFileDialogProps>) => {

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} PaperProps={{ sx: { height: "80vh" } }} maxWidth="xl" fullWidth>
                <DialogHeader
                    closeDialog={() => props.onClose()}
                    headline={'Select file'}
                />
                <DialogContent dividers>
                    <FileExplorer
                        onFileClick={(fileItem) => {
                            props.onConfirm && props.onConfirm(fileItem);
                            props.onClose();
                        }}
                        connectionId={props.connectionId || "1"}
                    />
                </DialogContent>
            </Dialog >
        </>
    );
}

export default SelectFileDialog;