import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, Button,
    FormControl, TextField, Grid, Box, Typography, InputLabel, Select, MenuItem,
} from '@mui/material';
import DialogHeader from '../../../../../shared/components/dialogs/DialogHeader';
import {MergeNodeModel} from "../MergeNodeModel";
import {propagateColsToChildren} from "../../../../../pages/home/Demo";

interface MergeNodeSettingsProps {
    open: boolean
    handleClose: () => void
    nodeModel: MergeNodeModel
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>
}

const mergeTypesIcons: { [key: string]: string} = {
    "Inner": "/img/join/Inner.svg",
    "Right outer excl.": "/img/join/Right_Outer_Exclusive.svg",
    "Right outer incl.": "/img/join/Right_Outer_Inclusive.svg",
    "Left outer excl.": "/img/join/Left_Outer_Exclusive.svg",
    "Left outer incl.": "/img/join/Right_Outer_Inclusive.svg",
    "Full outer excl.": "/img/join/Full_Outer_Exclusive.svg",
    "Full outer incl.": "/img/join/Full_Outer_Inclusive.svg"
}

export const DEFAULT_JOIN_TYPE_SELECTED = "Inner"

const MergeNodeSettings: React.FC<MergeNodeSettingsProps> = (props: MergeNodeSettingsProps) => {

    const dialogContentRef = React.useRef<HTMLDivElement>(null);
    const [inputColsTop, setInputColsTop] = useState<string[]>([]);
    const [inputColsBottom, setInputColsBottom] = useState<string[]>([]);
    const [selectedMergeTypeName, setSelectedMergeTypeName] = useState<string>(DEFAULT_JOIN_TYPE_SELECTED);
    const [joinFieldsTop, setJoinFieldsTop] = useState<string[]>([]);
    const [joinFieldsBottom, setJoinFieldsBottom] = useState<string[]>([]);

    const getColor = (colName: string) => {
        if (colName.startsWith('m_')) return 'orange';
        if (colName.startsWith('T_')) return 'green';
        if (colName.startsWith('B_')) return 'blue';
        return 'black';
    };

    // Function to update the element at a given index
    const updateJoinFieldsTopAtIndex = (index: number, newValue: string) => {

        setJoinFieldsTop((prevFields) => {
            // Make a shallow copy of the previous state array
            const updatedArray = [...prevFields];

            // Check if the index is within the bounds of the array
            if (index >= 0) {
                // Update the element at the specified index
                updatedArray[index] = newValue;
            } else {
                console.error("Index out of bounds");
            }

            return updatedArray;
        });
    };

    const updateJoinFieldsBottomAtIndex = (index: number, newValue: string) => {

        setJoinFieldsBottom((prevFields) => {
            // Make a shallow copy of the previous state array
            const updatedArray = [...prevFields];

            // Check if the index is within the bounds of the array
            if (index >= 0) {
                // Update the element at the specified index
                updatedArray[index] = newValue;
            } else {
                console.error("Index out of bounds");
            }

            return updatedArray;
        });
    };

    const onApply = () => {
        
        props.setSettingsSummary(selectedMergeTypeName + " by " + joinFieldsTop + (joinFieldsTop !== joinFieldsBottom ? ", " + joinFieldsBottom : ""))

        props.nodeModel.setComment(props.comment)
        props.nodeModel.setSelectedMergeTypeName(selectedMergeTypeName)
        props.nodeModel.setJoinFieldsTop(joinFieldsTop)
        props.nodeModel.setJoinFieldsBottom(joinFieldsBottom)

        const resColNames = props.nodeModel.calculateOutputCols_full()
        props.nodeModel.setOutputColumns(resColNames.withoutPrefixes)
        
        propagateColsToChildren(props.nodeModel)
        
        onClose()
    }

    const onClose = () => {
        props.handleClose && props.handleClose()
    }

    // dont propagate events to componente below
    React.useEffect(() => {
        if (!props.open) return
        const dialogContent = dialogContentRef.current;
        const preventScroll = (e: WheelEvent) => {
            // e.preventDefault();
            e.stopPropagation();
        };

        const attachEventListener = () => {
            if (dialogContent) {
                dialogContent.addEventListener('wheel', preventScroll);
            }
        };

        if (props.open) {
            // Use setTimeout to ensure the dialog content is rendered before attaching the event listener
            setTimeout(attachEventListener, 0);
        }

        return () => {
            if (dialogContent) {
                dialogContent.removeEventListener('wheel', preventScroll);
            }
        };
    }, [props.open, dialogContentRef]);

    React.useEffect(()=>{
        setInputColsTop(props.nodeModel.inputColumnsTop)
        // eslint-disable-next-line
    }, [props.nodeModel.inputColumnsTop])

    React.useEffect(()=>{
        setInputColsBottom(props.nodeModel.inputColumnsBottom)
        // eslint-disable-next-line
    }, [props.nodeModel.inputColumnsBottom])

    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(()=>{
        setJoinFieldsTop(props.nodeModel.joinFieldsTop)
        // eslint-disable-next-line
    }, [props.nodeModel.joinFieldsTop])
    React.useEffect(()=>{
        setJoinFieldsBottom(props.nodeModel.joinFieldsBottom)
        // eslint-disable-next-line
    }, [props.nodeModel.joinFieldsBottom])
    React.useEffect(()=>{
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    React.useEffect(()=>{
        setSelectedMergeTypeName(props.nodeModel.selectedMergeTypeName)
        // eslint-disable-next-line
    }, [props.nodeModel.selectedMergeTypeName])


    return (
        <>
            <Dialog open={props.open} onClose={onClose}
                    maxWidth="lg"
                    scroll="paper"
                    PaperProps={{ sx: { backgroundColor: "white" } }}
                    disableEnforceFocus
                    fullWidth>
                <DialogHeader
                    headline={"Merge "}
                    closeDialog={onClose}
                />
                <DialogContent ref={dialogContentRef}>
                    <Grid container alignItems="center">

                        <Grid item xs={2}>
                            <Box sx={{ flexGrow: 1, textAlign: 'center', marginBottom: '10px' }}>
                                <Grid container direction="column" alignItems="center" spacing={2}><>
                                    {Object.keys(mergeTypesIcons).map((keyName, index) => {
                                        const imgSrc = mergeTypesIcons[keyName]
                                        return (<Grid item key={index}>
                                            <Box sx={{ position: 'relative', width: 77, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', }}
                                                onClick={()=>setSelectedMergeTypeName(keyName)}
                                            >
                                                <img src={imgSrc} alt={`svg-${index}`} width="100%" height="100%"
                                                     style={{ position: 'absolute', top: 0, left: 0 }} />
                                                <Typography variant="caption" sx={{
                                                    position: 'absolute', color: 'black', padding: '4px 8px', borderRadius: '4px',
                                                    backgroundColor: (keyName === selectedMergeTypeName ?  'rgba(0, 0, 0, 0.5)' : "")

                                                }} >
                                                    {keyName}
                                                </Typography>
                                            </Box>
                                        </Grid>)
                                    })}
                                </></Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container spacing={2} justifyContent={"center"}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {selectedMergeTypeName !== DEFAULT_JOIN_TYPE_SELECTED ? `${selectedMergeTypeName}-join by these field(s):` : DEFAULT_JOIN_TYPE_SELECTED}
                                    </Typography>
                                </Grid>
                                {joinFieldsTop.map((joinFieldTopName, index) => (
                                    <React.Fragment key={index}>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id={`embedding-label-top-${index}`}>{"Join-Field Top"}</InputLabel>
                                                <Select
                                                    labelId={`embedding-label-top-${index}`}
                                                    value={joinFieldsTop[index]}
                                                    onChange={(e) => {
                                                        updateJoinFieldsTopAtIndex(index, e.target.value);
                                                        console.log("E:" + e);
                                                    }}
                                                    label={"Join-Field Top"}
                                                >
                                                    {inputColsTop.map((e) => (
                                                        <MenuItem key={e} value={e}>
                                                            {e}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id={`embedding-label-bottom-${index}`}>{"Join-Field Bottom"}</InputLabel>
                                                <Select
                                                    labelId={`embedding-label-bottom-${index}`}
                                                    value={joinFieldsBottom[index]}
                                                    onChange={(e) => {
                                                        updateJoinFieldsBottomAtIndex(index, e.target.value);
                                                        console.log("E:" + e.target.value);
                                                    }}
                                                    label={"Join-Field Bottom"}
                                                >
                                                    {inputColsBottom.map((e) => (
                                                        <MenuItem key={e} value={e}>
                                                            {e}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="embedding-label-new-top">{"Join-Field Top"}</InputLabel>
                                        <Select
                                            labelId="embedding-label-new-top"
                                            value={""}
                                            onChange={(e) => {
                                                updateJoinFieldsTopAtIndex(joinFieldsTop.length, e.target.value);
                                                // console.log("E:" + e);
                                            }}
                                            label={"Join-Field Top"}
                                        >
                                            {inputColsTop.map((e) => (
                                                <MenuItem key={e} value={e}>
                                                    {e}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="embedding-label-new-bottom">{"Join-Field Bottom"}</InputLabel>
                                        <Select
                                            labelId="embedding-label-new-bottom"
                                            value={""}
                                            onChange={(e) => {
                                                updateJoinFieldsBottomAtIndex(joinFieldsBottom.length, e.target.value);
                                                // console.log("E:" + e.target.value);
                                            }}
                                            label={"Join-Field Bottom"}
                                        >
                                            {inputColsBottom.map((e) => (
                                                <MenuItem key={e} value={e}>
                                                    {e}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {"Resulting Column Names:"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {(():any => {
                                        const resColNames = props.nodeModel.calculateOutputCols_full()
                                        return resColNames.withPrefixes.map((colNamePrx, index) => (
                                            <span key={index} style={{ color: getColor(colNamePrx) }}>
                                                {resColNames.withoutPrefixes[index]}, &nbsp;
                                            </span>
                                        ));
                                    })()}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Node Comment"
                                value={props.comment}
                                onChange={(e) => props.setComment(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onApply}>Apply</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MergeNodeSettings;