import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class SimpleCleanUpNodeModel extends SparkyBasicNodeModel {
    
    inputColumns: string[];
    removeLeadingTrailing: boolean;
    replaceBackslash: boolean;
    removeQuotes: boolean;

    constructor() {
        super("Node_SIMPLE_CLEAN_UP");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputTop"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputBottom"));

        this.inputColumns = [];
        
        this.removeLeadingTrailing = false;
        this.replaceBackslash = false;
        this.removeQuotes = false;
    }

    calculateOutputCols(): string[] {
        this.setOutputColumns(this.getInputColumns());
        return this.getInputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            inputColumns: this.inputColumns,
            comment: this.comment,
            removeLeadingTrailing: this.removeLeadingTrailing,
            replaceBackslash: this.replaceBackslash,
            removeQuotes: this.removeQuotes,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.inputColumns = event.data.inputColumns || [];
        this.comment = event.data.comment || "";
        this.removeLeadingTrailing = !!event.data.removeLeadingTrailing;
        this.replaceBackslash = !!event.data.replaceBackslash;
        this.removeQuotes = !!event.data.removeQuotes;
    }

    getInputColumns(): string[] {
        return this.inputColumns;
    }

    setInputColumns(cols: string[]): void {
        this.inputColumns = cols;
    }

    getOutputColumnsTop(): string[] {
        this.calculateOutputCols()
        return this.getOutputColumns()
    }

    getOutputColumnsBottom(): string[] {
        this.calculateOutputCols()
        return this.getOutputColumns()
    }

    setRemoveLeadingTrailing(val: boolean) {
        this.removeLeadingTrailing = val;
    }

    getRemoveLeadingTrailing(): boolean {
        return this.removeLeadingTrailing;
    }

    setReplaceBackslash(val: boolean) {
        this.replaceBackslash = val;
    }

    getReplaceBackslash(): boolean {
        return this.replaceBackslash;
    }

    setRemoveQuotes(val: boolean) {
        this.removeQuotes = val;
    }

    getRemoveQuotes(): boolean {
        return this.removeQuotes;
    }

    getComment(): string {
        return this.comment;
    }

    setComment(comment: string): void {
        this.comment = comment;
    }
}
