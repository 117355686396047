import * as React from "react";
import {SortNodeModel} from "./SortNodeModel";
import {
    DiagramEngine,
    PortModelAlignment,
    PortWidget,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {S} from "../../port/SimplePortWidget";
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration, {getPrimaryColorByNodeType} from "../DefaultNodeDecoration";
import {useStreamContext} from "../../../../contexts/StreamContext";
import SortNodeSettings from "./settings/SortNodeSettings";
import {SparkyNodeStatusInfo} from "../SparkyBasicNodeModel";

export interface SortNodeWidgetProps {
    node: SortNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

const SortNodeWidget: React.FC<SortNodeWidgetProps> = (
    props: SortNodeWidgetProps
) => {

    const {selectedNodeIds, selectNodeIds} = useStreamContext();
    
    const [settingsSummary, setSettingsSummary] = React.useState<string>(props.node.getSettingsSummary());
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [highlighted, setHighlighted] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const [nodeIsRunning, setNodeIsRunning] = React.useState<boolean>(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOnNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to allow dragging
        if (event.shiftKey) { // multi selection
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // remove from selected elements
                selectNodeIds(selectedNodeIds.filter(item => item !== props.node.getID()));
            } else {
                selectNodeIds([...selectedNodeIds, props.node.getID()])
            }
        } else { // single selection
            // selectNodeIds([props.node.getID()])
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // selectNodeIds([])
            } else {
                selectNodeIds([props.node.getID()])
            }
            console.log("Shift not pressed " + props.node.getID())
        }
    }

    const [numLinesOut, setNumLinesOut] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const listener = props.node.registerListener({
            eventDidFire: (event: any) => {
                if (event.function === 'nodeStarted') {
                    setNodeIsRunning(true);
                } else if (event.function === 'nodeEnded') {
                    setNodeIsRunning(false);
                } else if (event.function === 'statusInfoUpdated') {
                    const info = event.statusInfo as SparkyNodeStatusInfo
                    if (info.count >= 0) {
                        setNumLinesOut(info.count)
                    }
                }
            },
        });
        return () => { listener.deregister(); };
    }, [props.node]);

    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])


    return (
        <div
            className={"diamond-node"}
            style={{
                position: "relative",
                width: props.width,
                height: props.height,
            }}
            onDoubleClick={handleOpen}
            onClick={handleOnNodeClick}
        >
            <svg
                width="304"
                height="179"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                overflow="hidden"
            >
                <defs>
                    <filter
                        id="fx0-nodeSort"
                        x="-10%"
                        y="-10%"
                        width="120%"
                        height="120%"
                        filterUnits="userSpaceOnUse"
                        primitiveUnits="userSpaceOnUse"
                    >
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter
                        id="fx1-nodeSort"
                        x="-10%"
                        y="-10%"
                        width="120%"
                        height="120%"
                        filterUnits="userSpaceOnUse"
                        primitiveUnits="userSpaceOnUse"
                    >
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip2-nodeSort">
                        <rect x="55" y="22" width="304" height="179"/>
                    </clipPath>
                    <clipPath id="clip3-nodeSort">
                        <path
                            d="M276.287 29 276.287 33.2982 276.446 33.6806 276.446 52.4026C276.446 54.9876 274.349 57.0832 271.764 57.0832L258.863 57.0832 258.863 77.6662C258.863 80.7591 256.355 83.2664 253.261 83.2664L241.28 83.2664 241.28 102.789C241.28 106.468 238.297 109.45 234.618 109.45L223.698 109.45 223.698 128.527C223.698 132.452 220.515 135.633 216.59 135.633L206.115 135.633 206.115 155.362C206.115 158.927 203.224 161.816 199.659 161.816L191 161.816 191 188 319.492 188C334.132 188 346 176.135 346 161.5L346 55.5005C346 40.8647 334.132 29 319.492 29ZM55 22 359 22 359 201 55 201Z"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        />
                    </clipPath>
                    <clipPath id="clip4-nodeSort">
                        <path
                            d="M88.3926 29.0001C73.8163 29.0001 62 40.8647 62 55.5005L62 161.5C62 174.306 71.0468 184.991 83.0735 187.462L84.9502 187.652 86.6687 188 88.3921 188 88.3926 188 173.144 188 181.659 188C185.421 188 188.471 184.938 188.471 181.161L188.471 161.817 199.548 161.817C203.098 161.817 205.976 158.927 205.976 155.362L205.976 135.633 216.405 135.633C220.314 135.633 223.482 132.452 223.482 128.527L223.482 109.45 234.355 109.45C238.018 109.45 240.988 106.468 240.988 102.789L240.988 83.2664 252.917 83.2664C255.997 83.2664 258.494 80.7591 258.494 77.6663L258.494 57.0833 271.338 57.0833C273.913 57.0833 276 54.9877 276 52.4026L276 33.6807C276 31.0956 273.913 29.0001 271.338 29.0001L173.144 29.0001 172.048 29.0001ZM55 22 359 22 359 201 55 201Z"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        />
                    </clipPath>
                </defs>
                <g clipPath="url(#clip2-nodeSort)" transform="translate(-55 -22)">
                    <g clipPath="url(#clip3-nodeSort)">
                        <g filter="url(#fx0-nodeSort)" transform="translate(183 21)">
                            <g>
                                <path
                                    d="M96.1157 10.8284 139.32 10.8284C153.96 10.8284 165.828 22.6931 165.828 37.3289L165.828 143.328C165.828 157.964 153.96 169.828 139.32 169.828L10.8284 169.828 10.8284 143.645 19.4872 143.645C23.0528 143.645 25.9434 140.755 25.9434 137.191L25.9434 117.461 36.4181 117.461C40.3437 117.461 43.5261 114.28 43.5261 110.356L43.5261 91.2782 54.4463 91.2782C58.1259 91.2782 61.1088 88.2961 61.1088 84.6177L61.1088 65.0948 73.0896 65.0948C76.1833 65.0948 78.6914 62.5875 78.6914 59.4946L78.6914 38.9116 91.5921 38.9116C94.1779 38.9116 96.2741 36.8161 96.2741 34.231L96.2741 15.509 96.1157 15.1267Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"}
                                    strokeWidth="8"
                                    strokeMiterlimit="8"
                                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_SORT)}
                                    fillRule="evenodd"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        d="M276.287 29 319.492 29C334.132 29 346 40.8647 346 55.5005L346 161.5C346 176.135 334.132 188 319.492 188L191 188 191 161.816 199.659 161.816C203.224 161.816 206.115 158.927 206.115 155.362L206.115 135.633 216.59 135.633C220.515 135.633 223.698 132.452 223.698 128.527L223.698 109.45 234.618 109.45C238.297 109.45 241.28 106.468 241.28 102.789L241.28 83.2664 253.261 83.2664C256.355 83.2664 258.863 80.7591 258.863 77.6662L258.863 57.0832 271.764 57.0832C274.349 57.0832 276.446 54.9876 276.446 52.4026L276.446 33.6806 276.287 33.2982Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"}
                        strokeWidth="8"
                        strokeMiterlimit="8"
                        fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_SORT)}
                        fillRule="evenodd"
                    />
                    <g clipPath="url(#clip4-nodeSort)">
                        <g filter="url(#fx1-nodeSort)" transform="translate(54 21)">
                            <g>
                                <path
                                    d="M37.221 10.8285 120.877 10.8285 121.973 10.8285 220.167 10.8285C222.741 10.8285 224.828 12.924 224.828 15.5091L224.828 34.231C224.828 36.8161 222.741 38.9117 220.167 38.9117L207.322 38.9117 207.322 59.4947C207.322 62.5875 204.825 65.0949 201.745 65.0949L189.817 65.0949 189.817 84.6177C189.817 88.2962 186.847 91.2783 183.183 91.2783L172.311 91.2783 172.311 110.356C172.311 114.28 169.142 117.461 165.234 117.461L154.805 117.461 154.805 137.191C154.805 140.755 151.927 143.645 148.377 143.645L137.299 143.645 137.299 162.989C137.299 166.766 134.25 169.828 130.488 169.828L121.973 169.828 121.973 169.828 37.221 169.828 37.2205 169.828 35.4972 169.828 33.7786 169.48 31.9019 169.29C19.8753 166.819 10.8284 156.134 10.8284 143.328L10.8284 37.329C10.8284 22.6931 22.6448 10.8285 37.221 10.8285Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"}
                                    strokeWidth="8"
                                    strokeMiterlimit="8"
                                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_SORT)}
                                    fillRule="evenodd"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        d="M88.3926 29 172.048 29 173.144 29 271.338 29C273.913 29 276 31.0956 276 33.6807L276 52.4026C276 54.9877 273.913 57.0833 271.338 57.0833L258.494 57.0833 258.494 77.6663C258.494 80.7591 255.997 83.2664 252.917 83.2664L240.988 83.2664 240.988 102.789C240.988 106.468 238.018 109.45 234.355 109.45L223.482 109.45 223.482 128.527C223.482 132.452 220.314 135.633 216.405 135.633L205.976 135.633 205.976 155.362C205.976 158.927 203.098 161.817 199.548 161.817L188.471 161.817 188.471 181.161C188.471 184.938 185.421 188 181.659 188L173.144 188 173.144 188 88.3926 188 88.3921 188 86.6687 188 84.9501 187.652 83.0735 187.462C71.0468 184.991 61.9999 174.306 61.9999 161.5L61.9999 55.5005C61.9999 40.8647 73.8163 29 88.3926 29Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"}
                        strokeWidth="8"
                        strokeMiterlimit="8"
                        fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_SORT)}
                        fillRule="evenodd"
                    />
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_SORT}
                nodeIsRunning={nodeIsRunning}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
                numLinesOut={numLinesOut}
            />

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: -25,
                    position: "absolute",
                }}
                port={
                    props.node.getPort("input") ||
                    new SparkyELTPortModel(PortModelAlignment.LEFT, "input")
                }
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>
            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: props.width - 14,
                    position: "absolute",
                }}
                port={
                    props.node.getPort("output") ||
                    new SparkyELTPortModel(PortModelAlignment.RIGHT, "output")
                }
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <SortNodeSettings open={modalOpen}
                              handleClose={handleClose}
                              nodeModel={props.node}
                              comment={comment}
                              setComment={setComment}
                              setSettingsSummary={setSettingsSummary}
            />

        </div>
    );
};

export default SortNodeWidget;
