import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class GroupByNodeModel extends SparkyBasicNodeModel {
    
    groupedByColumns: string[];
    operationColumn: string;
    groupOperation: string;
    newColName: string;
    concatDelimiter: string;
    leftJoinResultsToOriginalData: boolean;  // if disabled this will return unique rows    

    constructor() {
        super("Node_GROUP_BY");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));
        this.groupedByColumns = [];
        this.operationColumn = "";
        this.groupOperation = "";
        this.newColName = "";
        this.concatDelimiter = "";
        this.leftJoinResultsToOriginalData = false;
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        let calcOutputCols = []
        if (this.leftJoinResultsToOriginalData) {
            calcOutputCols = this.getInputColumns()
        } else {
            calcOutputCols = [...this.getGroupedByColumns()];
        }
        console.log("Before push: ", calcOutputCols);

        const newCol = this.getNewColName();
        console.log("New column: ", newCol);

        if (!calcOutputCols.includes(newCol)) { // Prevent duplicate addition
            calcOutputCols.push(newCol);
        }

        console.log("After push:", calcOutputCols);

        // this.setOutputColumns(calcOutputCols)
        return calcOutputCols
    }


    serialize(): any {
        return {
            ...super.serialize(),
            groupedByColumns: this.groupedByColumns,
            operationColumn: this.operationColumn,
            groupOperation: this.groupOperation,
            newColName: this.newColName,
            comment: this.comment,
            concatDelimiter: this.concatDelimiter,
            leftJoinResultsToOriginalData: this.leftJoinResultsToOriginalData,
        };
    }

    deserialize(event: any): void {
        console.log("Deserializing event data: ", event.data);
        super.deserialize(event);
        this.groupedByColumns = event.data.groupedByColumns || [];
        this.operationColumn = event.data.operationColumn || "";
        this.groupOperation = event.data.groupOperation || "";
        this.newColName = event.data.newColName || "";
        this.comment = event.data.comment || "";
        this.concatDelimiter = event.data.delimiter || "";
        this.settingsSummary = this.calcSettingsSummary()
        this.leftJoinResultsToOriginalData = event.data.leftJoinResultsToOriginalData || false;
    }

    setGroupedByColumns(cols: string[]): void {
        console.log("setGroupedByColumns " + cols);
        console.trace()
        this.groupedByColumns = JSON.parse(JSON.stringify(cols)); // Ensures a true copy
    }

    getGroupedByColumns(): string[] {
        return this.groupedByColumns;
    }

    getOperationColumn(): string {
        return this.operationColumn;
    }

    setOperationColumn(column: string): void {
        this.operationColumn = column;
    }
    
    getGroupOperation(): string{
        return this.groupOperation;
    }
    
    setGroupOperation(value: string): void {
        this.groupOperation = value;
    }

    setNewColName(name: string): void {
        this.newColName = name;
    }

    getNewColName(): string {
        return this.newColName;
    }

    setConcatDelimiter(delimiter: string): void {
        this.concatDelimiter = delimiter;
    }

    getConcatDelimiter(): string {
        return this.concatDelimiter;
    }
    
    setLeftJoinResultsToOriginalData(leftJoinResultsToOriginalData: boolean): void {
        this.leftJoinResultsToOriginalData = leftJoinResultsToOriginalData;
    }
    
    getLeftJoinResultsToOriginalData(): boolean {
        return this.leftJoinResultsToOriginalData;
    }

    calcSettingsSummary() {
        const summary = `
            ${this.groupedByColumns}, 
            ${this.groupOperation}(${this.operationColumn}) 
            AS ${this.newColName}${this.concatDelimiter.length > 0 ? ", del: " + this.concatDelimiter : ""}`;

        return summary;
    }
}
