import React, {ReactNode, useState} from 'react';
import {
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Fab,
    Grid,
    Typography
} from '@mui/material';

export type ConnectionType = 'File: S3-Bucket' | 'File: HDFS' | 'File: SFTP' | 'File: WebDAV' | 'File: Samba' | 'File: Local'
    | 'File: SparkS3' | 'File: SparkHDFS'
    | 'Database: Postgres' | 'Database: Oracle' | 'Database: MariaDB' | 'Database: Hive'
    | 'Stream: Kafka' | 'Stream: Storm'
    | 'API: JSON'
    ;

export interface SourceOption {
    id: string,
    name: string,
    connectionType: ConnectionType,
    icon: ReactNode,
}

export interface ConnectionSelectProps {
    selected?: SourceOption,
    availableOptions: SourceOption[],
    icon: ReactNode,
    onSelect?: (sourceOption: SourceOption) => void,
}

const ConnectionSelect: React.FC<ConnectionSelectProps> = (props) => {

    const [selectedConnection, setSelectedConnection] = useState<SourceOption | null>(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleSourceOptionClick = (sourceOption: SourceOption) => {
        setSelectedConnection(sourceOption)
        if (props.onSelect) {
            props.onSelect(sourceOption);
        }
        handleClose();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        if (props.selected) {
            setSelectedConnection(props.selected)
        }
    }, [props.selected]);

    return (
        <>
            <Grid container>
                <Grid item xs={2} container justifyContent="center" alignItems="center">
                    <Fab onClick={handleClick} color="primary"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {props.icon}
                    </Fab>
                </Grid>
                <Grid item xs={10} sx={{alignItems: "left", paddingLeft: "0px"}}>
                    <Typography variant="h6">
                        <ListItemText
                            primary={(selectedConnection ? selectedConnection.name : "← Please select a connection")}
                            secondary={(selectedConnection ? selectedConnection.connectionType : "")}
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.availableOptions.map((sourceOption) => (
                    <MenuItem
                        key={sourceOption.name}
                        onClick={() =>
                            handleSourceOptionClick(sourceOption)
                        }
                    >
                        <ListItemIcon>
                            {sourceOption.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={sourceOption.name}
                            secondary={sourceOption.connectionType}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default ConnectionSelect;
