import ApiClient, {ClientOptions} from "./ApiClient";
import {createContext, useContext} from "react";
import {useNotificationContext} from "../contexts/NotificationContext";

export default class StreamApiClient extends ApiClient {
    public startStream = async (clusterId: string, streamId: string, options?: ClientOptions): Promise<void> => {
        /*const body = {
            clusterId: clusterId,
            steps: [
                {
                    id: 1,
                    parents: [],
                    operation: "Load data",
                    args: ["s3://sparky-etl-int-s3-be-filestorage/streams/1234567890/sourcedata/Food_Establishment_Inspection_Data_20240409.csv", "true"]
                },
                {
                    id: 3,
                    parents: ["1"],
                    operation: "Write data",
                    args: ["s3://sparky-etl-int-s3-be-filestorage/streams/1234567890/sourcedata/out.csv", "true"]
                }
            ]
        }*/
        // const streamId = "aa027e1d-867c-4d2b-9237-d16b5fc96754";
        return this.post(`/stream/${streamId}/run`, {body: {clusterID: clusterId}});
    }

    public saveStream = (streamId: string, streamName: string, globalDate: string, streamAsJSONString: string) => {
        return this.post(
            `/stream/${streamId}/save`,
            {body: {streamName: streamName, globalDate: globalDate, serializedStream: streamAsJSONString}}
        );
    }
}


export const StreamApiClientContext = createContext<StreamApiClient>(new StreamApiClient())

export const useStreamApiClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(StreamApiClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}