import * as React from "react";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { BaseClusterResponse, ClusterStatus } from "../clients/model/Cluster";
import { useClusterClient } from "../clients/ClusterClient";
import { useStreamApiClient } from "../clients/StreamApiClient";
import { useNotificationContext } from "./NotificationContext";

export interface ClusterContext {
    selectedClusterId?: string;
    selectedCluster?: BaseClusterResponse;
    availableClusters: BaseClusterResponse[];

    clusterLogs?: string;
    sparkLogs?: string;
    clusterLogsChanged: boolean;
    sparkLogsChanged: boolean;

    startCluster(): Promise<void>;
    stopCluster(): Promise<void>;
    setSelectedCluster(clusterId: string): void;

    clusterLogsViewed(): void;
    sparkLogsViewed(): void;
    startStream(streamId: string): void;
}

const defaultClusterContext: ClusterContext = {
    availableClusters: [],
    clusterLogsChanged: false,
    sparkLogsChanged: false,
    startCluster: async function (): Promise<void> {
        throw new Error("Function not implemented.");
    },
    stopCluster: async function (): Promise<void> {
        throw new Error("Function not implemented.");
    },
    setSelectedCluster: function (clusterId: string): void {
        throw new Error("Function not implemented.");
    },
    clusterLogsViewed: function (): void {
        throw new Error("Function not implemented.");
    },
    sparkLogsViewed: function (): void {
        throw new Error("Function not implemented.");
    },
    startStream: function (streamId: string): void {
        throw new Error("Function not implemented.");
    }
};

export const ClusterContextHolder = createContext<ClusterContext>(defaultClusterContext);
export const useClusterContext = () => useContext(ClusterContextHolder);

export const ClusterContextProvider: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    const clusterClient = useClusterClient();
    const streamClient = useStreamApiClient();
    const notificationCtx = useNotificationContext();

    const [selectedClusterId, setSelectedClusterId] = useState<string>();
    const [clusters, setClusters] = useState<BaseClusterResponse[]>([]);
    const [clusterLogs, setClusterLogs] = useState<string>();
    const [sparkLogs, setSparkLogs] = useState<string>();
    const [clusterLogsChanged, setClusterLogsChanged] = useState(false);
    const [sparkLogsChanged, setSparkLogsChanged] = useState(false);

    const loadClusters = useCallback(async () => {
        try {
            const response = await clusterClient.getAllClusters();
            setClusters(response);
            
            // If no cluster is selected yet, select an active cluster or the first one
            if (!selectedClusterId) {
                const activeCluster = response.find(c => 
                    [
                        ClusterStatus.RUNNING,
                        ClusterStatus.BUSY,
                        ClusterStatus.STARTING,
                        ClusterStatus.PENDING
                    ].includes(c.status)
                );
                setSelectedClusterId(activeCluster?.id || response[0]?.id);
            }
        } catch (error) {
            console.error('Failed to load clusters:', error);
        }
    }, [clusterClient, selectedClusterId]);

    const fetchLogs = useCallback(async () => {
        if (selectedClusterId) {
            try {
                const clusterLogResponse = await clusterClient.getClusterLogs(selectedClusterId);
                setClusterLogs(prevLogs => {
                    if (prevLogs !== clusterLogResponse && clusterLogResponse.length > 0) {
                        setClusterLogsChanged(true);
                    }
                    return clusterLogResponse;
                });

                const sparkLogResponse = await clusterClient.getSparkLogs(selectedClusterId);
                setSparkLogs(prevLogs => {
                    if (prevLogs !== sparkLogResponse && sparkLogResponse.length > 0) {
                        setSparkLogsChanged(true);
                    }
                    return sparkLogResponse;
                });
            } catch (error) {
                console.error('Failed to fetch logs:', error);
            }
        }
    }, [clusterClient, selectedClusterId]);

    useEffect(() => {
        loadClusters();
        const clustersInterval = setInterval(loadClusters, 30000);
        return () => clearInterval(clustersInterval);
    }, [loadClusters]);

    useEffect(() => {
        if (selectedClusterId) {
            fetchLogs();
            const logsInterval = setInterval(fetchLogs, 10000);
            return () => clearInterval(logsInterval);
        }
    }, [selectedClusterId, fetchLogs]);

    const selectedCluster = clusters.find(c => c.id === selectedClusterId);

    const clusterContext: ClusterContext = {
        selectedClusterId,
        selectedCluster,
        availableClusters: clusters,
        clusterLogs,
        sparkLogs,
        clusterLogsChanged,
        sparkLogsChanged,
        async startCluster() {
            if (selectedClusterId) {
                try {
                    await clusterClient.startCluster(selectedClusterId);
                    await loadClusters();
                } catch (error) {
                    console.error('Failed to start cluster:', error);
                }
            }
        },
        async stopCluster() {
            if (selectedClusterId) {
                try {
                    await clusterClient.stopCluster(selectedClusterId);
                    await loadClusters();
                } catch (error) {
                    console.error('Failed to stop cluster:', error);
                }
            }
        },
        setSelectedCluster(clusterId: string) {
            setSelectedClusterId(clusterId);
        },
        clusterLogsViewed() {
            setClusterLogsChanged(false);
        },
        sparkLogsViewed() {
            setSparkLogsChanged(false);
        },
        startStream(streamId: string) {
            if (selectedClusterId) {
                streamClient.startStream(selectedClusterId, streamId)
                    .then(() => notificationCtx.notify({ 
                        message: 'Successfully triggered stream, please wait...', 
                        severity: 'success' 
                    }))
                    .catch(error => {
                        console.error('Failed to start stream:', error);
                    });
            }
        }
    };

    return (
        <ClusterContextHolder.Provider value={clusterContext}>
            {props.children}
        </ClusterContextHolder.Provider>
    );
};
