import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    FormControl,
    TextField,
    Grid,
    Tabs,
    Tab,
    Box,
    Select,
    MenuItem,
    IconButton,
    Autocomplete, Typography, InputLabel, List, ListItemText, ListItemButton, Chip,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {
    BasicCalculation,
    ConstanceCalculation,
    CalculateNodeModel,
    DateCalculation,
    DaysUntilCalculation,
    ValueType,
    FormularCalculation, UniqueIDCol
} from "../CalculateNodeModel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {propagateColsToChildren} from "../../../../../pages/home/Demo";

interface CalculateNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: CalculateNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
    globalStreamDate: string;
    streamLastRun: string;
}

const dateFormats = [
    "yyyy-MM-dd",
    "dd/MM/yyyy",
    "dd-MM-yyyy",
    "yyyy/MM/dd",
    "yyyy.MM.dd",
    "dd.MM.yyyy",
];
const units = ["year", "month", "day"];
const operators = ["+", "-"];
const operations = ["+", "-", "*", "/"];

const CalculateNodeSettings: React.FC<CalculateNodeSettingsProps> = (
    props: CalculateNodeSettingsProps
) => {
    const dialogContentRef = React.useRef<HTMLDivElement>(null);
    const [tabIndex, setTabIndex] = useState(0);
    
    const [uniqueIDcols, setUniqueIDcols] = useState<UniqueIDCol[]>([
        {
            outputColumn: "",
        }
    ]);
    const [dateCalculations, setDateCalculations] = useState<DateCalculation[]>([
        {
            column: "",
            dateFormat: "",
            operator: "+",
            value: 1,
            unit: "day",
            outputColumn: "",
        },
    ]);

    const [daysUntilCalculations, setDaysUntilCalculations] = useState<
        DaysUntilCalculation[]
    >([
        {
            column: "",
            targetDate: "",
            dateFormat: "",
            outputColumn: "",
        },
    ]);
    
    const [basicCalculations, setBasicCalculations] = useState<
        BasicCalculation[]
    >([
        {
            column: "",
            operation: "",
            value: {type: "string", value: ""},
            outputColumn: "",
        },
    ]);
    
    const [constanceCalculations, setConstanceCalculations] = useState<
        ConstanceCalculation[]
    >([
        {
            value: "",
            outputColumn: "",
        },
    ]);

    const [formularCalculations, setFormularCalculations] = useState<FormularCalculation[]
    >([
        {
            value: "",
            outputColumn: "",
        },
    ]);
    
    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    const onApply = () => {
        // Filtere leere oder unvollständige Einträge aus den Berechnungen
        const filteredBasicCalculations: BasicCalculation[] = basicCalculations.filter(
                (calc) =>
                    calc.column && calc.operation && calc.value && calc.outputColumn
        );

        const filteredDateCalculations = dateCalculations.filter(
            (calc) =>
                calc.column &&
                calc.dateFormat &&
                calc.operator &&
                calc.value !== undefined &&
                calc.unit &&
                calc.outputColumn
        );

        const filteredDaysUntilCalculations = daysUntilCalculations.filter(
            (calc) =>
                calc.column && calc.targetDate && calc.dateFormat && calc.outputColumn
        );

        const filteredConstanceCalculations = constanceCalculations.filter(
            (calc) =>
                calc.outputColumn && calc.value
        );

        const filteredFormularCalculations = formularCalculations.filter(
            (calc) =>
                calc.outputColumn && calc.value
        );

        const filteredUniqueIDcols = uniqueIDcols.filter(
            (calc) =>
                calc.outputColumn
        );
        
        // --
        props.nodeModel.setBasicCalculations(filteredBasicCalculations);
        props.nodeModel.setConstanceCalculations(filteredConstanceCalculations);
        props.nodeModel.setFormularCalculations(filteredFormularCalculations);
        props.nodeModel.setDateCalculations(filteredDateCalculations);
        props.nodeModel.setDaysUntilCalculations(filteredDaysUntilCalculations);
        props.nodeModel.setUniqueIDcol(filteredUniqueIDcols);
        // --

        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols());
        props.nodeModel.setComment(props.comment);
        props.nodeModel.setSettingsSummary(props.nodeModel.calcSettingsSummary());
        props.setSettingsSummary(props.nodeModel.calcSettingsSummary())

        propagateColsToChildren(props.nodeModel)

        onClose();
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const updateBasicCalculation = (
        index: number,
        key: "column" | "operation" | "value" | "outputColumn",
        value: string | ValueType
    ) => {
        const newCalculations = [...basicCalculations];
        if (key === "value") {
            newCalculations[index].value = value as ValueType;
        } else {
            newCalculations[index][key] = value as string;
        }
        setBasicCalculations(newCalculations);
    };

    const updateConstanceCalculation = (
        index: number,
        key: "value" | "outputColumn",
        value: string
    ) => {
        const newCalculations = [...constanceCalculations];
        if (key === "value") {
            newCalculations[index].value = value;
        } else {
            newCalculations[index][key] = value;
        }
        setConstanceCalculations(newCalculations);
    };

    const updateFormularCalculations = (
        index: number,
        key: "value" | "outputColumn",
        value: string
    ) => {
        const newCalculations = [...formularCalculations];
        if (key === "value") {
            newCalculations[index].value = value;
        } else {
            newCalculations[index][key] = value;
        }
        setFormularCalculations(newCalculations);
    };

    const updateUniqueIDcols = (
        index: number,
        key: "outputColumn",
        value: string
    ) => {
        const newCalculations = [...uniqueIDcols];
        newCalculations[index][key] = value;
        setUniqueIDcols(newCalculations);
    };

    const updateDateCalculation = (
        index: number,
        key: keyof DateCalculation,
        value: string | number
    ) => {
        const newCalculations = [...dateCalculations];

        if (key === "value" && typeof value === "number") {
            newCalculations[index][key] = value;
        } else if (
            (key === "column" ||
                key === "dateFormat" ||
                key === "operator" ||
                key === "unit" ||
                key === "outputColumn") &&
            typeof value === "string"
        ) {
            newCalculations[index][key] = value;
        } else {
            console.error(`Invalid type for key ${key}: ${typeof value}`);
        }

        setDateCalculations(newCalculations);
    };

    const updateDaysUntilCalculation = (
        index: number,
        key: keyof DaysUntilCalculation,
        value: string
    ) => {
        const newCalculations = [...daysUntilCalculations];
        newCalculations[index][key] = value;
        setDaysUntilCalculations(newCalculations);
    };

    const addDateCalculation = () => {
        setDateCalculations([
            ...dateCalculations,
            {
                column: "",
                dateFormat: "yyyy-MM-dd",
                operator: "+",
                value: 1,
                unit: "day",
                outputColumn: "",
            },
        ]);
    };

    const addDaysUntilCalculation = () => {
        setDaysUntilCalculations([
            ...daysUntilCalculations,
            {
                column: "",
                targetDate: "",
                dateFormat: "",
                outputColumn: "",
            },
        ]);
    };

    const removeDateCalculation = (index: number) => {
        const newCalculations = [...dateCalculations];
        newCalculations.splice(index, 1);
        setDateCalculations(newCalculations);
    };

    const removeDaysUntilCalculation = (index: number) => {
        const newCalculations = [...daysUntilCalculations];
        newCalculations.splice(index, 1);
        setDaysUntilCalculations(newCalculations);
    };

    const addBasicCalculation = () => {
        setBasicCalculations([
            ...basicCalculations,
            {
                column: "",
                operation: "",
                value: {type: "string", value: ""},
                outputColumn: "",
            },
        ]);
    };

    const removeBasicCalculation = (index: number) => {
        const newCalculations = [...basicCalculations];
        newCalculations.splice(index, 1);
        setBasicCalculations(newCalculations);
    };

    const removeConstanceCalculation = (index: number) => {
        const newCalculations = [...constanceCalculations];
        newCalculations.splice(index, 1);
        setConstanceCalculations(newCalculations);
    };


    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(() => {
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    
    React.useEffect(() => {
        if (props.nodeModel.basicCalculations.length > 0) {
            setBasicCalculations(props.nodeModel.basicCalculations)    
        }
        // eslint-disable-next-line
    }, [props.nodeModel.basicCalculations])

    React.useEffect(() => {
        if (props.nodeModel.constanceCalculations.length > 0) {
            setConstanceCalculations(props.nodeModel.constanceCalculations)
        }
        // eslint-disable-next-line
    }, [props.nodeModel.constanceCalculations])

    React.useEffect(() => {
        if (props.nodeModel.formularCalculations.length > 0) {
            setFormularCalculations(props.nodeModel.formularCalculations)
        }
        // eslint-disable-next-line
    }, [props.nodeModel.formularCalculations])


    React.useEffect(() => {
        if (props.nodeModel.dateCalculations.length > 0) {
            setDateCalculations(props.nodeModel.dateCalculations)
        }
        // eslint-disable-next-line
    }, [props.nodeModel.dateCalculations])
    
    React.useEffect(() => {
        if (props.nodeModel.daysUntilCalculations.length > 0) {
            setDaysUntilCalculations(props.nodeModel.daysUntilCalculations)
        }
        // eslint-disable-next-line
    }, [props.nodeModel.daysUntilCalculations])
    
    React.useEffect(() => {
        if (props.nodeModel.uniqueIDcols.length > 0) {
            setUniqueIDcols(props.nodeModel.uniqueIDcols)
        }
        // eslint-disable-next-line
    }, [props.nodeModel.uniqueIDcols])

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader headline={"Derive New Column"} closeDialog={onClose}/>
            <DialogContent ref={dialogContentRef}>
                <div style={{display: 'flex', flexDirection: 'column', height: '50vh', marginBottom: '10px'}}>
                    <Box sx={{borderBottom: 1, borderColor: "divider", marginBottom: 2}}>
                        <Tabs
                            value={tabIndex}
                            onChange={handleTabChange}
                            aria-label="Calculate Node Tabs"
                        >
                            <Tab label="Basic Calc"/>
                            <Tab label="Constant Value"/>
                            <Tab label="Formular"/>
                            <Tab label="Dates: +/- Constants"/>
                            <Tab label="Day Differences"/>
                            <Tab label="Unique ID"/>
                        </Tabs>
                    </Box>

                    {/* Basic Calc */}
                    {tabIndex === 0 && (
                        <Box>
                            {basicCalculations.map((calc, index) => (
                                <Grid
                                    container
                                    spacing={2}
                                    key={index}
                                    sx={{marginBottom: 2}}
                                    alignItems="center"
                                >
                                    <Grid container item xs={3} alignItems="center" spacing={1}>
                                        <Grid item xs>
                                            <TextField
                                                label="New Column Name"
                                                value={calc.outputColumn}
                                                onChange={(e) =>
                                                    updateBasicCalculation(
                                                        index,
                                                        "outputColumn",
                                                        e.target.value
                                                    )
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>=</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id={`select-Input-${index}`}>Input-Column</InputLabel>
                                            <Select
                                                label={"Input-Column"}
                                                labelId={`select-Input-${index}`}
                                                value={calc.column}
                                                onChange={(e) =>
                                                    updateBasicCalculation(index, "column", e.target.value)
                                                }
                                                fullWidth
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Column
                                                </MenuItem>
                                                {props.nodeModel.getInputColumns().map((col) => (
                                                    <MenuItem key={col} value={col}>
                                                        {col}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id={`select-operation-${index}`}>Operation</InputLabel>
                                            <Select
                                                label={"Operation"}
                                                labelId={`select-operation-${index}`}
                                                value={calc.operation}
                                                onChange={(e) =>
                                                    updateBasicCalculation(index, "operation", e.target.value)
                                                }
                                                fullWidth
                                            >
                                                <MenuItem value="" disabled>
                                                    Operation
                                                </MenuItem>
                                                {operations.map((op) => (
                                                    <MenuItem key={op} value={op}>
                                                        {op}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            freeSolo
                                            options={props.nodeModel.getInputColumns()}
                                            value={
                                                calc.value.type === "string"
                                                    ? calc.value.value
                                                    : calc.value.column
                                            }
                                            onInputChange={(event, newInputValue) => {
                                                updateBasicCalculation(index, "value", {
                                                    type: "string",
                                                    value: newInputValue || "",
                                                });
                                            }}
                                            onChange={(event, newValue) => {
                                                if (
                                                    newValue &&
                                                    props.nodeModel.getInputColumns().includes(newValue)
                                                ) {
                                                    updateBasicCalculation(index, "value", {
                                                        type: "column",
                                                        column: newValue,
                                                    });
                                                } else {
                                                    updateBasicCalculation(index, "value", {
                                                        type: "string",
                                                        value: (newValue as string) || "",
                                                    });
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select or type a value"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={() => removeBasicCalculation(index)}
                                            color="error"
                                        >
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <IconButton onClick={addBasicCalculation} color="success">
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Box>
                    )}

                    {/* Constant Value */}
                    {tabIndex === 1 && (
                        <Box>
                            {constanceCalculations.map((calc, index) => (
                                <Grid
                                    container
                                    spacing={2}
                                    key={index}
                                    sx={{marginBottom: 2}}
                                    alignItems="center"
                                >
                                    <Grid container item xs={3} alignItems="center" spacing={1}>
                                        <Grid item xs>
                                            <TextField
                                                placeholder="New Column Name"
                                                value={calc.outputColumn}
                                                onChange={(e) =>
                                                    updateConstanceCalculation(
                                                        index,
                                                        "outputColumn",
                                                        e.target.value
                                                    )
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>=</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            placeholder="Constant Value"
                                            value={calc.value}
                                            onChange={(e) =>
                                                updateConstanceCalculation(
                                                    index,
                                                    "value",
                                                    e.target.value
                                                )
                                            }
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={() => removeConstanceCalculation(index)}
                                            color="error"
                                        >
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <IconButton onClick={addBasicCalculation} color="success">
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Box>
                    )}

                    {/* Formular */}
                    {tabIndex === 2 &&
                        <Box sx={{padding: 2}}>
                            <Grid container item sx={{marginBottom: 2}} alignItems="center" spacing={2}>
                                <Grid container item xs={12} alignItems="center" spacing={2}>
                                    <Grid item xs>
                                        <Typography>
                                            Evaluate a formular and store the results in a new column. This follows the
                                            PySpark F.expr() syntax for enhanced use-cases.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {/* Left Section - List */}
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={{mb: 1, color: "text.secondary"}}>
                                        Input-Columns. Double-click to add to formular
                                    </Typography>
                                    <Box sx={{
                                        maxHeight: 200,
                                        overflowY: "auto",
                                        border: "1px solid #ccc",
                                        borderRadius: 1
                                    }}>
                                        <List>
                                            {props.nodeModel.getInputColumns().map((name) => (
                                                <ListItemButton
                                                    key={name}
                                                    dense
                                                    onDoubleClick={() =>
                                                        updateFormularCalculations(0, "value", formularCalculations[0].value + " " + name + " ")
                                                    }
                                                >
                                                    <ListItemText primary={name}/>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Box>
                                </Grid>

                                {/* Middle Section - Multi-line TextField */}
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={{mb: 1, color: "text.secondary"}}>
                                        Formular
                                    </Typography>
                                    <TextField
                                        onChange={(e) =>
                                            updateFormularCalculations(
                                                0,
                                                "value",
                                                e.target.value
                                            )
                                        }
                                        multiline
                                        rows={5}
                                        fullWidth
                                        value={formularCalculations[0].value}
                                        variant="outlined"
                                    />
                                    <Chip sx={{margin: "3px"}} label={"Basics: col1 * 3 + col2"} variant="outlined"
                                          size="small"/>
                                    <Chip sx={{margin: "3px"}} label={"Round: round(col1, 2)"} variant="outlined"
                                          size="small"/>
                                    <Chip sx={{margin: "3px"}} label={"Concat: col1 ||','|| col2"} variant="outlined"
                                          size="small"/>
                                </Grid>

                                {/* Right Section - Single-line TextField */}
                                <Grid item xs={4}>
                                    <Typography variant="subtitle2" sx={{mb: 1, color: "text.secondary"}}>
                                        Output Column
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        value={formularCalculations[0].outputColumn}
                                        onChange={(e) => updateFormularCalculations(0, "outputColumn", e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    }

                    {/* Dates: +/- Constants */}
                    {tabIndex === 3 && (
                        <Box>
                            {dateCalculations.map((calc, index) => (
                                <Grid
                                    container
                                    spacing={2}
                                    key={index}
                                    sx={{marginBottom: 2}}
                                    alignItems="center"
                                >
                                    <Grid container item xs={3} alignItems="center" spacing={1}>
                                        <Grid item xs>
                                            <TextField
                                                placeholder="New Column Name"
                                                value={calc.outputColumn}
                                                onChange={(e) =>
                                                    updateDateCalculation(
                                                        index,
                                                        "outputColumn",
                                                        e.target.value
                                                    )
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>=</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            value={calc.column}
                                            onChange={(e) =>
                                                updateDateCalculation(index, "column", e.target.value)
                                            }
                                            displayEmpty
                                            fullWidth
                                        >
                                            <MenuItem value="" disabled>
                                                Select Column
                                            </MenuItem>
                                            {props.nodeModel.getInputColumns().map((col) => (
                                                <MenuItem key={col} value={col}>
                                                    {col}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            value={calc.dateFormat}
                                            onChange={(e) =>
                                                updateDateCalculation(index, "dateFormat", e.target.value)
                                            }
                                            displayEmpty
                                            fullWidth
                                        >
                                            <MenuItem value="" disabled>
                                                Select Format
                                            </MenuItem>
                                            {dateFormats.map((format) => (
                                                <MenuItem key={format} value={format}>
                                                    {format}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Select
                                            value={calc.operator}
                                            onChange={(e) =>
                                                updateDateCalculation(index, "operator", e.target.value)
                                            }
                                            displayEmpty
                                            fullWidth
                                        >
                                            {operators.map((op) => (
                                                <MenuItem key={op} value={op}>
                                                    {op}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField
                                            type="number"
                                            value={calc.value}
                                            onChange={(e) =>
                                                updateDateCalculation(
                                                    index,
                                                    "value",
                                                    Number(e.target.value)
                                                )
                                            }
                                            placeholder="Value"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            value={calc.unit}
                                            onChange={(e) =>
                                                updateDateCalculation(index, "unit", e.target.value)
                                            }
                                            displayEmpty
                                            fullWidth
                                        >
                                            <MenuItem value="" disabled>
                                                Select Unit
                                            </MenuItem>
                                            {units.map((unit) => (
                                                <MenuItem key={unit} value={unit}>
                                                    {unit}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={() => removeDateCalculation(index)}
                                            color="error"
                                        >
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <IconButton onClick={addDateCalculation} color="success">
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Box>
                    )}

                    {/* Dates: Day Differences */}
                    {tabIndex === 4 && (
                        <Box>
                            <Grid container item sx={{marginBottom: 2}} alignItems="center" spacing={2}>
                                <Grid container item xs={12} alignItems="center" spacing={1}>
                                    <Grid item xs>
                                        <Typography>Calculate the days between the given column and the selected date.
                                            Instead of giving a fixed date to substract you can specify <b>*SGD*</b>
                                            (stream global date, {props.globalStreamDate}) as
                                            placeholder. The results are stored in the new column.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {daysUntilCalculations.map((calc, index) => (
                                <Grid
                                    container
                                    spacing={2}
                                    key={index}
                                    sx={{marginBottom: 2}}
                                    alignItems="center"
                                >
                                    <Grid container item xs={4} alignItems="center" spacing={1}>
                                        <Grid item xs>
                                            <TextField
                                                label="New Column Name"
                                                placeholder="New Column Name"
                                                value={calc.outputColumn}
                                                onChange={(e) =>
                                                    updateDaysUntilCalculation(
                                                        index,
                                                        "outputColumn",
                                                        e.target.value
                                                    )
                                                }
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography>=</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id={`select-label-${index}`}>Input-Column</InputLabel>
                                            <Select
                                                label={"Input-Column"}
                                                labelId={`select-label-${index}`} // Link label to Select
                                                value={calc.column}
                                                onChange={(e) =>
                                                    updateDaysUntilCalculation(index, "column", e.target.value)
                                                }
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Column
                                                </MenuItem>
                                                {props.nodeModel.getInputColumns().map((col) => (
                                                    <MenuItem key={col} value={col}>
                                                        {col}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id={`select-label-frmt-${index}`}>Date format</InputLabel>

                                            <Select
                                                label={"Date format"}
                                                labelId={`select-label-frmt-${index}`}
                                                value={calc.dateFormat || ""}
                                                onChange={(e) =>
                                                    updateDaysUntilCalculation(
                                                        index,
                                                        "dateFormat",
                                                        e.target.value
                                                    )
                                                }
                                                fullWidth
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Format
                                                </MenuItem>
                                                {dateFormats.map((format) => (
                                                    <MenuItem key={format} value={format}>
                                                        {format}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item xs={3} alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography>-</Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <TextField
                                                fullWidth
                                                label="yyyy-MM-dd or *SGD*"
                                                name="targetDate"
                                                value={calc.targetDate}
                                                onChange={(e) => {
                                                    const newValue = e.target.value.toUpperCase();
                                                    // Allow "SGD" completely
                                                    if (newValue === "*SGD*" ||newValue === "*" 
                                                        || newValue === "*S" || newValue === "*SG" || newValue === "*SGD"
                                                    ) {
                                                        updateDaysUntilCalculation(index, "targetDate", newValue);
                                                        return
                                                    }

                                                    // Check if input follows the "yyyy-MM-dd" pattern progressively
                                                    const regex = [
                                                        /^\d{0,4}$/,         // Year (0-4 digits)
                                                        /^\d{4}-\d{0,2}$/,   // Year-Month (0-2 digits in month)
                                                        /^\d{4}-\d{2}-\d{0,2}$/ // Year-Month-Day (0-2 digits in day)
                                                    ];
                                                    const isValidTargetDateInput = regex.some((r) => r.test(newValue));
                                                    if (isValidTargetDateInput) {
                                                        updateDaysUntilCalculation(index, "targetDate", newValue);
                                                    }
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={() => removeDaysUntilCalculation(index)}
                                            color="error"
                                        >
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <IconButton onClick={addDaysUntilCalculation} color="success">
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </Box>
                    )}

                    {/* ID */}
                    {tabIndex === 5 && (
                        <Box>
                            <Grid container item sx={{marginBottom: 2}} alignItems="center" spacing={2}>
                                <Grid container item xs={12} alignItems="center" spacing={1}>
                                    <Grid item xs>
                                        <Typography>
                                            Generate a new col holding a unique ID for each line. The ID is unique and
                                            increasing by line but not necessary consecutive.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={3} alignItems="center" spacing={1}>
                                <Grid item xs>
                                    <TextField
                                        label="New Column Name"
                                        value={uniqueIDcols[0].outputColumn}
                                        onChange={(e) =>
                                            updateUniqueIDcols(0, "outputColumn", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>= Unique ID</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </div>
                
                <Grid item xs={12} sx={{marginTop: '15px'}}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Node Comment"
                            value={props.comment}
                            onChange={(e) => props.setComment(e.target.value)}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CalculateNodeSettings;
