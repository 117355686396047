import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export type SparkyFilterCondition = { 
    column: string; 
    operator: string; 
    value: string 
}

export class FilterNodeModel extends SparkyBasicNodeModel {
    outputColumnsTop: string[];
    outputColumnsBottom: string[];

    filteredConditions: string[];
    filteredConditionsObjects: { conditions: SparkyFilterCondition[] }[];
    skippedConditions: string[];

    constructor() {
        super("Node_FILTER");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputTop"));
        this.addPort(
            new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputBottom")
        );

        this.outputColumnsTop = [];
        this.outputColumnsBottom = [];

        this.filteredConditions = [];
        this.filteredConditionsObjects = [{conditions: []}]
        this.skippedConditions = [];
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        this.setOutputColumnsTop(this.getInputColumns())
        this.setOutputColumnsBottom(this.getInputColumns())
        return this.getOutputColumnsTop()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,

            filteredConditions: this.filteredConditions,
            filteredConditionsObjects: this.filteredConditionsObjects,
            skippedConditions: this.skippedConditions,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment || ""

        this.filteredConditions = event.data.filteredConditions || []
        this.filteredConditionsObjects = event.data.filteredConditionsObjects
        this.skippedConditions = event.data.skippedConditions || []

        this.settingsSummary = this.calcSettingsSummary()
    }

    setOutputColumnsTop(cols: string[]) {
        this.outputColumnsTop = cols;
    }

    getOutputColumnsTop(): string[] {
        return this.outputColumnsTop;
    }

    setOutputColumnsBottom(cols: string[]) {
        this.outputColumnsBottom = cols;
    }

    getOutputColumnsBottom(): string[] {
        return this.outputColumnsBottom;
    }

    setFilteredConditions(cond: string[]) {
        this.filteredConditions = cond;
    }

    getFilteredConditions(): string[] {
        return this.filteredConditions;
    }

    setFilteredConditionsObjects(cond: { conditions: SparkyFilterCondition[] }[]) {
        this.filteredConditionsObjects = cond;
    }

    getFilteredConditionsObjects(): { conditions: SparkyFilterCondition[] }[] {
        return this.filteredConditionsObjects;
    }

    setSkippedConditions(cond: string[]) {
        this.skippedConditions = cond;
    }

    getSkippedConditions(): string[] {
        return this.skippedConditions;
    }

    calcSettingsSummary() {
        
        const formattedConditions = this.filteredConditionsObjects
            .map((group) =>
                group.conditions
                    .filter(
                        (condition) =>
                            condition.column && condition.operator // && condition.value
                    )
                    .map(
                        (condition) =>
                            `\`${condition.column}\` ${condition.operator} ${
                                (condition.value.length === 0 ? `''` :
                                    (isNaN(Number(condition.value))
                                    ? `'${condition.value}'`
                                    : condition.value)
                                )
                            }`
                    )
            )
            .filter((groupConditions) => groupConditions.length > 0)
            .map((groupConditions) => `(${groupConditions.join(" AND ")})`)
            .join(" OR ");
        
        return formattedConditions;
    }
}
