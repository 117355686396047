import { DiagramModel, DeserializeEvent } from '@projectstorm/react-diagrams';

export class MyDiagramModel extends DiagramModel {
    
    streamId: string;
    streamName: string;
    globalDate: string;

    constructor(streamId: string, options: { globalDate?: string, streamName?: string } = {}) {
        super();
            this.streamId = streamId;
            this.streamName = options.streamName || 'New Stream';
            this.globalDate = options.globalDate || '1999-12-24';
    }

    // Serialize to JSON for saving state
    serialize(): any {
        return {
            ...super.serialize(),
            streamId: this.streamId,
            streamName: this.streamName,
            globalDate: this.globalDate,
        };
    }

    // Deserialize from JSON for loading state
    deserialize(event: DeserializeEvent<this>): void {
        super.deserialize(event);
        this.streamId = event.data.streamId;
        this.streamName = event.data.streamName || 'New Stream';
        this.globalDate = event.data.globalDate || '1999-12-24';
    }
}
