import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import * as React from 'react';
import DialogHeader from './DialogHeader';
import { FileItem } from '../common/Explorer';
import FileExplorer from '../common/FileExplorer';
import { useState } from 'react';

export interface ICreateFileDialogProps {
    open: boolean
    onClose(): void
    onConfirm?(fileItem: FileItem): void
    connectionId?: string;
}

const CreateFileDialog: React.FunctionComponent<
    React.PropsWithChildren<ICreateFileDialogProps>
> = (props: React.PropsWithChildren<ICreateFileDialogProps>) => {

    const [path, setPath] = useState('/');

    const [fileName, setFileName] = useState('');

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} PaperProps={{ sx: { height: "80vh" } }} maxWidth="xl" fullWidth>
                <DialogHeader
                    closeDialog={() => props.onClose()}
                    headline={'Where to save file?'}
                />
                <DialogContent dividers>
                    <TextField fullWidth label="File name" value={fileName} onChange={(e) => setFileName(e.target.value)} />
                    <FileExplorer
                        onFolderClick={(folderItem) => {
                            setPath(folderItem.path);
                        }}
                        onFileClick={(fileItem) => {
                            setPath(fileItem.path);
                            setFileName(fileItem.name);
                        }}
                        connectionId={props.connectionId || "1"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={!fileName}
                        variant="contained"
                        onClick={() => {
                            props.onConfirm && props.onConfirm({
                                name: fileName, path: path,
                                type: 'file',
                                size: 0,
                                key: path + fileName
                            });
                            props.onClose();
                        }}>Save</Button>
                </DialogActions>
            </Dialog >
        </>
    );
}

export default CreateFileDialog;