import {PortModelAlignment} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export type ValueType =
    | {
    type: "string";
    value: string
}
    | {
    type: "column";
    column: string
};

export type BasicCalculation = {
    column: string;
    operation: string;
    value: ValueType;
    outputColumn: string;
}

export type ConstanceCalculation = {
    value: string;
    outputColumn: string;
}

export type FormularCalculation = {
    value: string;
    outputColumn: string;
}

export type UniqueIDCol = {
    outputColumn: string;
}

export type DateCalculation = {
    column: string;
    dateFormat: string;
    operator: string;
    value: number;
    unit: string;
    outputColumn: string;
};

export type DaysUntilCalculation = {
    column: string;
    targetDate: string;
    dateFormat: string;
    outputColumn: string;
};

export class CalculateNodeModel extends SparkyBasicNodeModel {

    basicCalculations: BasicCalculation[];
    constanceCalculations: ConstanceCalculation[];
    formularCalculations: FormularCalculation [];
    dateCalculations: DateCalculation[];
    daysUntilCalculations: DaysUntilCalculation[];
    uniqueIDcols: UniqueIDCol[];

    constructor() {
        super("Node_CALCULATE");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));

        this.basicCalculations = [];
        this.constanceCalculations = [];
        this.formularCalculations = [];
        this.dateCalculations = [];
        this.daysUntilCalculations = [];
        this.uniqueIDcols = [];
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        const allCalculations = [
            ...this.basicCalculations,
            ...this.constanceCalculations,
            ...this.formularCalculations,
            ...this.dateCalculations,
            ...this.daysUntilCalculations,
            ...this.uniqueIDcols,
        ];
        const allNewCols = allCalculations.map((c) => c.outputColumn)
        this.setOutputColumns(this.getInputColumns().concat(allNewCols))
        return this.getOutputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,

            basicCalculations: this.basicCalculations,
            constanceCalculations: this.constanceCalculations,
            formularCalculations: this.formularCalculations,
            dateCalculations: this.dateCalculations,
            daysUntilCalculations: this.daysUntilCalculations,
            uniqueIDcols: this.uniqueIDcols,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment || "";

        this.basicCalculations = event.data.basicCalculations || [];
        this.constanceCalculations = event.data.constanceCalculations || [];
        this.formularCalculations = event.data.formularCalculations || [];
        this.dateCalculations = event.data.dateCalculations || [];
        this.daysUntilCalculations = event.data.daysUntilCalculations || [];
        this.uniqueIDcols = event.data.uniqueIDcols || [];
        
        this.settingsSummary = this.calcSettingsSummary()
    }


    getBasicCalculations(): BasicCalculation[] {
        return this.basicCalculations;
    }

    setBasicCalculations(calculations: BasicCalculation[]): void {
        this.basicCalculations = calculations;
    }

    getConstanceCalculations(): ConstanceCalculation[] {
        return this.constanceCalculations;
    }

    setConstanceCalculations(calculations: ConstanceCalculation[]): void {
        this.constanceCalculations = calculations;
    }

    getFormularCalculations(): FormularCalculation[] {
        return this.formularCalculations;
    }

    setFormularCalculations(calculations: FormularCalculation[]): void {
        this.formularCalculations = calculations;
    }

    getDateCalculations(): DateCalculation[] {
        return this.dateCalculations;
    }

    setDateCalculations(calculations: DateCalculation[]): void {
        this.dateCalculations = calculations;
    }

    getDaysUntilCalculations(): DaysUntilCalculation[] {
        return this.daysUntilCalculations;
    }

    setDaysUntilCalculations(calculations: DaysUntilCalculation[]): void {
        this.daysUntilCalculations = calculations;
    }
    
    getUniqueIDcols(): UniqueIDCol[] {
        return this.uniqueIDcols;
    }
    
    setUniqueIDcol(uniqueIDcols: UniqueIDCol[]): void {
        this.uniqueIDcols = uniqueIDcols;
    }

    calcSettingsSummary() {
        const allCalculations = [
            ...this.basicCalculations,
            ...this.dateCalculations,
            ...this.daysUntilCalculations,
            ...this.constanceCalculations,
        ];
        let res = allCalculations
            .map((c) => {
                if ("operator" in c && "unit" in c) {
                    // Datumsberechnung
                    return `${c.column} ${c.operator} ${c.value} ${c.unit} -> ${c.outputColumn}`;
                } else if ("targetDate" in c) {
                    // Tage bis Datenstand
                    return `${c.column} until ${c.targetDate} -> ${c.outputColumn}`;
                } else if ("operation" in c) {
                    // Grundrechenarten
                    return `${c.column} ${c.operation} ${c.value} -> ${c.outputColumn}`;
                } else {
                    return `${c.outputColumn} -> ${c.value}`;
                }
            })
            .join(", ") || ""
        
        if (this.uniqueIDcols.length > 0) {
            res = res + " " + this.uniqueIDcols[0].outputColumn + " = unique"
        }

        if (this.formularCalculations.length > 0) {
            res = res + " " + this.formularCalculations[0].outputColumn + " = formular"
        }
        
        return res;
    }

}
