import * as React from 'react';
import {DiagramEngine, PortModelAlignment, PortWidget} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import { S } from '../../port/SimplePortWidget';
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration, {getPrimaryColorByNodeType} from "../DefaultNodeDecoration";
import {RemoveColNodeModel} from "../removeCols/RemoveColNodeModel";
import {useStreamContext} from "../../../../contexts/StreamContext";
import {SparkyNodeStatusInfo} from "../SparkyBasicNodeModel";
import RemoveColsNodeSettings from "../removeCols/settings/RemoveColsNodeSettings";

export interface RenameColsNodeWidgetProps {
    node: RemoveColNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

const RenameColsNodeWidget: React.FC<RenameColsNodeWidgetProps> = (props: RenameColsNodeWidgetProps) => {

    const {selectedNodeIds, selectNodeIds} = useStreamContext();

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const [nodeIsRunning, setNodeIsRunning] = React.useState<boolean>(false);
    const [settingsSummary, setSettingsSummary] = React.useState<string>(props.node.getSettingsSummary());
    const [highlighted, setHighlighted] = React.useState<boolean>(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOnNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to allow dragging
        if (event.shiftKey) { // multi selection
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // remove from selected elements
                selectNodeIds(selectedNodeIds.filter(item => item !== props.node.getID()));
            } else {
                selectNodeIds([...selectedNodeIds, props.node.getID()])
            }
        } else { // single selection
            // selectNodeIds([props.node.getID()])
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // selectNodeIds([])
            } else {
                selectNodeIds([props.node.getID()])
            }
            console.log("Shift not pressed " + props.node.getID())
        }
    }

    const [numLinesOut, setNumLinesOut] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const listener = props.node.registerListener({
            eventDidFire: (event: any) => {
                if (event.function === 'nodeStarted') {
                    setNodeIsRunning(true);
                } else if (event.function === 'nodeEnded') {
                    setNodeIsRunning(false);
                } else if (event.function === 'statusInfoUpdated') {
                    const info = event.statusInfo as SparkyNodeStatusInfo
                    if (info.count >= 0) {
                        setNumLinesOut(info.count)
                    }
                }
            },
        });
        return () => { listener.deregister(); };
    }, [props.node]);

    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])
        
    return (
        <div
            className={'diamond-node'}
            style={{
                position: 'relative',
                width: props.width,
                height: props.height
            }}
            onDoubleClick={handleOpen}
            onClick={handleOnNodeClick}
        >
            <svg width="303" height="179" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                <defs>
                    <filter id="fx0-nodeRenameCols" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx1-nodeRenameCols" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx2-nodeRenameCols" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip3-nodeRenameCols">
                        <rect x="501" y="22" width="303" height="179"/>
                    </clipPath>
                    <clipPath id="clip4-nodeRenameCols">
                        <path
                            d="M534.407 29C519.823 29 508 40.8647 508 55.5005L508 161.5C508 176.135 519.823 188 534.407 188L704 188 704 29ZM501 22 804 22 804 201 501 201Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip5-nodeRenameCols">
                        <path d="M715 29 715 188 740 188 740 29ZM501 22 804 22 804 201 501 201Z" fillRule="evenodd"
                              clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip6-nodeRenameCols">
                        <path
                            d="M750 29 750 188 765.135 188C779.972 188 792 176.135 792 161.5L792 55.5005C792 40.8647 779.972 29 765.135 29ZM501 22 804 22 804 201 501 201Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip3-nodeRenameCols)" transform="translate(-501 -22)">
                    <g clipPath="url(#clip4-nodeRenameCols)">
                        <g filter="url(#fx0-nodeRenameCols)" transform="translate(500 21)">
                            <g>
                                <path
                                    d="M37.2352 10.8284 206.828 10.8284 206.828 169.828 37.2352 169.828C22.6511 169.828 10.8284 157.964 10.8284 143.328L10.8284 37.3289C10.8284 22.6931 22.6511 10.8284 37.2352 10.8284Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_RENAME_COL)}
                                    fillRule="evenodd"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M534.407 29 704 29 704 188 534.407 188C519.823 188 508 176.135 508 161.5L508 55.5005C508 40.8647 519.823 29 534.407 29Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_RENAME_COL)} fillRule="evenodd"/>
                    <g clipPath="url(#clip5-nodeRenameCols)">
                        <g filter="url(#fx1-nodeRenameCols)" transform="translate(707 21)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="159" stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_RENAME_COL)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="715" y="29" width="25" height="159" stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_RENAME_COL)}/>
                    <g clipPath="url(#clip6-nodeRenameCols)">
                        <g filter="url(#fx2-nodeRenameCols)" transform="translate(742 21)">
                            <g>
                                <path
                                    d="M42 0 26.8647 0C12.0278 0 0 11.8647 0 26.5005L0 132.5C0 147.135 12.0278 159 26.8647 159L42 159Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_RENAME_COL)}
                                    fillRule="evenodd" transform="matrix(-1 0 0 1 52.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M42 0 26.8647 0C12.0278 0 0 11.8647 0 26.5005L0 132.5C0 147.135 12.0278 159 26.8647 159L42 159Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_RENAME_COL)} fillRule="evenodd"
                        transform="matrix(-1 0 0 1 792 29)"/>
                    <path
                        d="M691.5 42.1668C691.5 39.0371 694.037 36.5001 697.167 36.5001L755.833 36.5001C758.963 36.5001 761.5 39.0371 761.5 42.1668L761.5 64.8333C761.5 67.963 758.963 70.5001 755.833 70.5001L697.167 70.5001C694.037 70.5001 691.5 67.963 691.5 64.8333Z"
                        stroke="#172C51" strokeWidth="0.333333" strokeMiterlimit="8" fill="#FFFFFF"
                        fillRule="evenodd"/>
                    <path d="M714 42 714 64.2923" stroke="#404040" strokeWidth="4" strokeMiterlimit="8"
                          fill="none" fillRule="evenodd"/>
                    <path d="M707 42 719.647 42.0001" stroke="#404040" strokeWidth="4" strokeMiterlimit="8"
                          fill="none" fillRule="evenodd"/>
                    <path d="M707 64 719.647 64.0001" stroke="#404040" strokeWidth="4" strokeMiterlimit="8"
                          fill="none" fillRule="evenodd"/>
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_REMOVE_COL}
                nodeIsRunning={nodeIsRunning}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
                numLinesOut={numLinesOut}
            />

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: -25,
                    position: 'absolute'
                }}
                port={props.node.getPort('input') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'input')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: props.width - 14,
                    position: 'absolute'
                }}
                port={props.node.getPort('output') || new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <RemoveColsNodeSettings open={modalOpen}
                                    handleClose={handleClose}
                                    nodeModel={props.node}
                                    comment={comment}
                                    setComment={setComment}
                                    setSettingsSummary={setSettingsSummary}
            />

        </div>
    );
}

export default RenameColsNodeWidget;