import { PlayArrow } from '@mui/icons-material';
import StopIcon from '@mui/icons-material/Stop';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { Fab } from '@mui/material';
import * as React from 'react';
import { useClusterContext } from '../../../contexts/ClusterContext';
import {ClusterStatus, StreamStatus} from '../../../clients/model/Cluster';
import DisabledToolTip from '../../../components/common/DisabledToolTip';
import MyTimer from "../../../shared/components/streamComponents/MyTimer";

export interface IStartStreamProps {
    saveStreamHandler: () => Promise<any>
    setStreamRunning: (running: boolean) => void
    streamId: string
    status: StreamStatus
    setStreamStatus: (status: StreamStatus) => void
}

const StartStream: React.FunctionComponent<IStartStreamProps> = (props: IStartStreamProps) => {
    const clusterCtx = useClusterContext();

    const start = () => {
        props.setStreamRunning(false)
        props.setStreamStatus(StreamStatus.STARTING) // wait
        if (clusterCtx.selectedCluster) {
            props.saveStreamHandler().then(()=>{
                clusterCtx.startStream(props.streamId);    
                props.setStreamRunning(true)
            })
        }
    };

    const canExecute = clusterCtx.selectedCluster && 
        clusterCtx.selectedCluster.status === ClusterStatus.RUNNING
    ;

    return (
        <>
            <DisabledToolTip
                disabled={!canExecute}
                tooltip="Cluster is not ready"
            >
                <div style={{
                    position: 'absolute',
                    bottom: "10px",
                    right: "50%",
                    left: "50%",
                    display: 'flex',
                    flexDirection: 'column', // Stack elements vertically
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {/* MyTimer Above */}
                    <MyTimer nodeIsRunning={props.status === StreamStatus.RUNNING || false} />

                    {/* Floating Action Button */}
                    <Fab
                        disabled={!canExecute}
                        onClick={start}
                        color={
                            (props.status === StreamStatus.STOPPED ? "success" : // run it
                                (props.status === StreamStatus.RUNNING ? "error"  // stop it
                                        : "default" // wait
                                ))}
                        sx={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {
                            (props.status === StreamStatus.STOPPED ? <PlayArrow style={{ fontSize: 50 }}/> : // run it
                                (props.status === StreamStatus.RUNNING ? <StopIcon style={{ fontSize: 50 }}/>  // stop it
                                        : <HourglassEmptyIcon style={{ fontSize: 50 }}/> // wait
                                ))}
                    </Fab>
                </div>
            </DisabledToolTip>

        </>
    );
};

export default StartStream;