import * as React from "react";
import {CalculateNodeModel} from "./CalculateNodeModel";
import {
    DiagramEngine,
    PortModelAlignment,
    PortWidget,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {S} from "../../port/SimplePortWidget";
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration from "../DefaultNodeDecoration";
import CalculateNodeSettings from "./settings/CalculateNodeSettings";
import {useStreamContext} from "../../../../contexts/StreamContext";
import {SparkyNodeStatusInfo} from "../SparkyBasicNodeModel";

export interface CalculateNodeWidgetProps {
    node: CalculateNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

export const CalculateNodeWidget = (props: CalculateNodeWidgetProps) => {

    const {selectedNodeIds, selectNodeIds} = useStreamContext();
    
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const [nodeIsRunning, setNodeIsRunning] = React.useState<boolean>(false);
    const [settingsSummary, setSettingsSummary] = React.useState<string>(props.node.getSettingsSummary());
    const [highlighted, setHighlighted] = React.useState<boolean>(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOnNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to allow dragging
        if (event.shiftKey) { // multi selection
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // remove from selected elements
                selectNodeIds(selectedNodeIds.filter(item => item !== props.node.getID()));
            } else {
                selectNodeIds([...selectedNodeIds, props.node.getID()])
            }
        } else { // single selection
            // selectNodeIds([props.node.getID()])
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // selectNodeIds([])
            } else {
                selectNodeIds([props.node.getID()])
            }
            console.log("Shift not pressed " + props.node.getID())
        }
    }

    const [numLinesOut, setNumLinesOut] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const listener = props.node.registerListener({
            eventDidFire: (event: any) => {
                if (event.function === 'nodeStarted') {
                    setNodeIsRunning(true);
                } else if (event.function === 'nodeEnded') {
                    setNodeIsRunning(false);
                } else if (event.function === 'statusInfoUpdated') {
                    const info = event.statusInfo as SparkyNodeStatusInfo
                    if (info.count >= 0) {
                        setNumLinesOut(info.count)
                    }
                }
            },
        });
        return () => { listener.deregister(); };
    }, [props.node]);
    
    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])

    return (
        <div
            className={"diamond-node"}
            style={{
                position: "relative",
                width: props.width,
                height: props.height,
            }}
            onDoubleClick={handleOpen}
            onClick={handleOnNodeClick}
        >
            <svg width="304" height="178" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                <defs>
                    <filter id="fx0-nodeAdd" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx1-nodeAdd" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx2-nodeAdd" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx3-nodeAdd" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip4-nodeAdd">
                        <rect x="465" y="29" width="304" height="178"/>
                    </clipPath>
                    <clipPath id="clip5-nodeAdd">
                        <path
                            d="M498.541 36C483.883 36 472 47.7901 472 62.3338L472 167.666C472 182.21 483.883 194 498.541 194L669 194 669 36ZM465 29 769 29 769 207 465 207Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip6-nodeAdd">
                        <path d="M679 36 679 194 704 194 704 36ZM465 29 769 29 769 207 465 207Z" fillRule="evenodd"
                              clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip7-nodeAdd">
                        <path
                            d="M715 36 715 194 729.775 194C744.259 194 756 182.21 756 167.666L756 62.3338C756 47.7901 744.259 36 729.775 36ZM465 29 769 29 769 207 465 207Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip8-nodeAdd">
                        <path
                            d="M692.5 41.5001C683.111 41.5001 675.5 48.8874 675.5 58.0001 675.5 67.1128 683.111 74.5001 692.5 74.5001 701.889 74.5001 709.5 67.1128 709.5 58.0001 709.5 48.8874 701.889 41.5001 692.5 41.5001ZM465 29 769 29 769 207 465 207Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip4-nodeAdd)" transform="translate(-465 -29)">
                    <g clipPath="url(#clip5-nodeAdd)">
                        <g filter="url(#fx0-nodeAdd)" transform="translate(464 28)">
                            <g>
                                <path
                                    d="M37.3699 10.8284 207.828 10.8284 207.828 168.828 37.3699 168.828C22.7114 168.828 10.8284 157.038 10.8284 142.495L10.8284 37.1622C10.8284 22.6185 22.7114 10.8284 37.3699 10.8284Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                                    fill="#D3CC84"
                                    fillRule="evenodd"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M498.541 36 669 36 669 194 498.541 194C483.883 194 472 182.21 472 167.666L472 62.3338C472 47.7901 483.883 36 498.541 36Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill="#D3CC84"
                        fillRule="evenodd"/>
                    <g clipPath="url(#clip6-nodeAdd)">
                        <g filter="url(#fx1-nodeAdd)" transform="translate(671 28)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="158"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8" fill="#D3CC84"/>
                            </g>
                        </g>
                    </g>
                    <rect x="679" y="36" width="25" height="158" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill="#D3CC84"/>
                    <g clipPath="url(#clip7-nodeAdd)">
                        <g filter="url(#fx2-nodeAdd)" transform="translate(707 28)">
                            <g>
                                <path
                                    d="M41 0 26.2251 0C11.7414 0 0 11.7901 0 26.3338L0 131.666C0 146.21 11.7414 158 26.2251 158L41 158Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                                    fill="#D3CC84"
                                    fillRule="evenodd" transform="matrix(-1 0 0 1 51.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M41 0 26.2251 0C11.7414 0 0 11.7901 0 26.3338L0 131.666C0 146.21 11.7414 158 26.2251 158L41 158Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill="#D3CC84"
                        fillRule="evenodd"
                        transform="matrix(-1 0 0 1 756 36)"/>
                    <g clipPath="url(#clip8-nodeAdd)">
                        <g filter="url(#fx3-nodeAdd)" transform="translate(671 37)">
                            <g>
                                <path
                                    d="M7.32849 23.8285C7.32849 14.7158 14.9396 7.32848 24.3285 7.32848 33.7173 7.32848 41.3285 14.7158 41.3285 23.8285 41.3285 32.9412 33.7173 40.3285 24.3285 40.3285 14.9396 40.3285 7.32849 32.9412 7.32849 23.8285Z"
                                    stroke="#172C51" strokeMiterlimit="8" fill="#FFFFFF" fillRule="evenodd"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M675.5 58.0001C675.5 48.8874 683.111 41.5001 692.5 41.5001 701.889 41.5001 709.5 48.8874 709.5 58.0001 709.5 67.1127 701.889 74.5001 692.5 74.5001 683.111 74.5001 675.5 67.1127 675.5 58.0001Z"
                        stroke="#172C51" strokeWidth="0.333333" strokeMiterlimit="8" fill="#FFFFFF"
                        fillRule="evenodd"/>
                    <path d="M692 46 692 68.2923" stroke="#404040" strokeWidth="4" strokeMiterlimit="8"
                          fill="none" fillRule="evenodd"/>
                    <path d="M681 57 703.292 56.9999" stroke="#404040" strokeWidth="4" strokeMiterlimit="8"
                          fill="none" fillRule="evenodd"/>
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_CALCULATE}
                nodeIsRunning={nodeIsRunning}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
                numLinesOut={numLinesOut}
            />

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: -25,
                    position: "absolute",
                }}
                port={
                    props.node.getPort("input") ||
                    new SparkyELTPortModel(PortModelAlignment.LEFT, "input")
                }
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: props.width - 14,
                    position: "absolute",
                }}
                port={
                    props.node.getPort("output") ||
                    new SparkyELTPortModel(PortModelAlignment.RIGHT, "output")
                }
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <CalculateNodeSettings
                open={modalOpen}
                handleClose={handleClose}
                nodeModel={props.node}
                comment={comment}
                setComment={setComment}
                setSettingsSummary={setSettingsSummary}
                globalStreamDate={"1980-11-02"}
                streamLastRun={"2004-01-26"}
            />
        </div>
    );
};
