import {PortModelAlignment} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from '../../port/SparkyELTPortModel';
import FileManagementApiClient from "../../../../clients/FileManagementApiClient";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class ReadNodeModel extends SparkyBasicNodeModel {

    slectedConnectionId: string;
    selectedSourceElement: string;
    delimiter: string;

    constructor(slectedConnectionId: string, selectedSourceElement: string) {
        super('Node_READ');

        this.slectedConnectionId = slectedConnectionId;
        this.selectedSourceElement = selectedSourceElement;
        this.delimiter = ",";

        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));
    }

    async calculateOutputCols(): Promise<string[]> {
        try {
            // const connectionId = nodeModel.getSelectedConnectionId()
            const fileClient = new FileManagementApiClient();
            const selFile = this.getSelectedSourceElement();
            const tmp = selFile.split("/");
            const path = tmp.slice(0, -1).join("/");
            const filenam = tmp[tmp.length - 1];

            // Await the file preview
            const result: any = await fileClient.getFilePreview(path, filenam, this.getSelectedConnectionId());

            let colTypes: Record<string, string> = {};
            for (const colName in result.fields) {
                colTypes[colName] = "string";
            }
            console.log(result.fields);

            // Set the columns on success
            this.setOutputColumns(result.fields);
        } catch (error) {
            // Return empty array on error
            return [];
        }

        // Finally, return whatever was set as output columns
        return this.getOutputColumns();
    }
    
    serialize(): any {
        return {
            ...super.serialize(),
            slectedConnectionId: this.slectedConnectionId,
            selectedSourceElement: this.selectedSourceElement,
            delimiter: this.delimiter,
            comment: this.comment
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.slectedConnectionId = event.data.slectedConnectionId;
        this.selectedSourceElement = event.data.selectedSourceElement;
        this.comment = event.data.comment;
        this.delimiter = event.data.delimiter;
        this.settingsSummary = this.calcSettingsSummary()
    }

    getSelectedConnectionId(): string {
        return this.slectedConnectionId;
    }

    setSelectedConnectionId(value: string): void {
        this.slectedConnectionId = value;
    }

    getSelectedSourceElement(): string {
        return this.selectedSourceElement;
    }

    setSelectedSourceElement(value: string): void {
        this.selectedSourceElement = value;
    }
    
    getDelimiter(): string {
        return this.delimiter;
    }
    
    setDelimiter(value: string): void {
        this.delimiter = value;
    }

    calcSettingsSummary() {
        const tmp = this.getSelectedSourceElement().split("/")
        return tmp[tmp.length - 1];
    }


}