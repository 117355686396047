import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";

export interface IMyTimerProps {
    nodeIsRunning: boolean;
    reset?: boolean;
}

const MyTimer: React.FunctionComponent<IMyTimerProps> = ({ nodeIsRunning, reset }) => {
    const [startTime, setStartTime] = useState<number | null>(null);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [timerVisible, setTimerVisible] = useState(false);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (nodeIsRunning) {
            setStartTime((prevStartTime) => prevStartTime ?? Date.now() - elapsedTime);
            setTimerVisible(true);

            interval = setInterval(() => {
                setElapsedTime(Date.now() - (startTime ?? Date.now()));
            }, 100);
        } else {
            if (interval) clearInterval(interval);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [nodeIsRunning, elapsedTime, startTime]);

    useEffect(() => {
        if (reset) {
            setStartTime(null);
            setElapsedTime(0);
            setTimerVisible(false);
        }
    }, [reset]);

    // Formatting with leading zeros
    const minutes = String(Math.floor(elapsedTime / 60000)).padStart(2, "0");
    const seconds = String(Math.floor((elapsedTime % 60000) / 1000)).padStart(2, "0");
    const milliseconds = String(elapsedTime % 1000).padStart(3, "0");

    if (!timerVisible) return null;

    return (
        <Typography variant="body2">
            {minutes}:{seconds}.{milliseconds}
        </Typography>
    );
};

export default MyTimer;
