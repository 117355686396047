import {PortModelAlignment} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from '../../port/SparkyELTPortModel';
import {DEFAULT_JOIN_TYPE_SELECTED} from "./settings/MergeNodeSettings";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class MergeNodeModel extends SparkyBasicNodeModel {

    inputColumnsTop: string[];
    inputColumnsBottom: string[];
    joinFieldsTop: string[];
    joinFieldsBottom: string[];
    selectedMergeTypeName: string;

    constructor() {
        super('Node_MERGE');
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputTop'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputBottom'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));

        this.inputColumnsTop = []
        this.inputColumnsBottom = []
        this.joinFieldsTop = []
        this.joinFieldsBottom = []
        this.selectedMergeTypeName = DEFAULT_JOIN_TYPE_SELECTED;
    }

    calculateOutputCols(): string[] {
        const tmp = this.calculateOutputCols_full()
        this.setOutputColumns(tmp.withoutPrefixes);
        return tmp.withoutPrefixes
    }

    serialize(): any {
        return {
            ...super.serialize(),
            joinFieldsTop: this.joinFieldsTop,
            joinFieldsBottom: this.joinFieldsBottom,
            selectedMergeTypeName: this.selectedMergeTypeName,
            comment: this.comment
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.joinFieldsTop = event.data.joinFieldsTop;
        this.joinFieldsBottom = event.data.joinFieldsBottom;
        this.selectedMergeTypeName = event.data.selectedMergeTypeName;
        this.comment = event.data.comment;
        this.settingsSummary = this.calcSettingsSummary()
    }

    calculateOutputCols_full(): { withPrefixes: string[], withoutPrefixes: string[] } {

        // Helper function to generate unique column names
        const generateUniqueName = (baseName: string, prefix: string, existingNames: Set<string>): string => {
            let uniqueName = `${prefix}${baseName}`;
            let counter = 1;
            while (existingNames.has(uniqueName)) {
                uniqueName = `${prefix}${baseName}_${counter}`;
                counter++;
            }
            return uniqueName;
        };

        const resultColsWithPrefixes: string[] = [];
        const resultColsWithoutPrefixes: string[] = [];
        const existingNames: Set<string> = new Set();

        // Add join columns first (from joinFields1)
        this.joinFieldsTop.forEach((field, index) => {
            const baseName = field
            const uniqueName = generateUniqueName(baseName, "", existingNames);
            resultColsWithPrefixes.push("m_" + uniqueName);
            resultColsWithoutPrefixes.push(uniqueName);
            existingNames.add(uniqueName);
        });

        // Add remaining columns from the first input file with prefix "B_"
        this.inputColumnsTop.forEach(col => {
            if (!this.joinFieldsTop.includes(col)) {
                const uniqueName = generateUniqueName(col, "", existingNames);
                resultColsWithPrefixes.push("T_" + uniqueName);
                resultColsWithoutPrefixes.push(uniqueName);
                existingNames.add(uniqueName);
            }
        });

        // Add remaining columns from the second input file with prefix "T_"
        this.inputColumnsBottom.forEach(col => {
            if (!this.joinFieldsBottom.includes(col)) {
                const uniqueName = generateUniqueName(col, "", existingNames);
                resultColsWithPrefixes.push("B_" + uniqueName);
                resultColsWithoutPrefixes.push(uniqueName);
                existingNames.add(uniqueName);
            }
        });
        return {withPrefixes: resultColsWithPrefixes, withoutPrefixes: resultColsWithoutPrefixes};
    }
    
    getInputColumnsTop(): string[] {
        return this.inputColumnsTop
    }

    setInputColumnsTop(cols: string[]): void {
        this.inputColumnsTop = cols
    }

    getInputColumnsBottom(): string[] {
        return this.inputColumnsBottom
    }

    setInputColumnsBottom(cols: string[]): void {
        this.inputColumnsBottom = cols
    }

    getJoinFieldsTop(): string[] {
        return this.joinFieldsTop
    }

    setJoinFieldsTop(cols: string[]): void {
        this.joinFieldsTop = cols
    }

    getJoinFieldsBottom(): string[] {
        return this.joinFieldsBottom
    }

    setJoinFieldsBottom(cols: string[]): void {
        this.joinFieldsBottom = cols
    }

    getSelectedMergeTypeName(): string {
        return this.selectedMergeTypeName
    }

    setSelectedMergeTypeName(selectedMergeTypeName: string): void {
        this.selectedMergeTypeName = selectedMergeTypeName
    }

    calcSettingsSummary() {
        return "Merge: implement me!";
    }


}